*{ /*star targets all elements to reset margin and remove whitespace*/
    margin: 0;
    padding: 0;
  }

.App {
    background-image: linear-gradient(90deg, rgb(33,33,33) 0%,transparent 59%),repeating-linear-gradient(45deg, rgba(168, 168, 168,0.1) 0px, rgba(168, 168, 168,0.1) 1px,transparent 1px, transparent 13px),repeating-linear-gradient(135deg, rgba(168, 168, 168,0.1) 0px, rgba(168, 168, 168,0.1) 1px,transparent 1px, transparent 13px),linear-gradient(90deg, rgb(33,33,33),rgb(33,33,33));
    height: 200vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    object-fit: fill;
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
}

.BtnHome {
  background-color: rgba(0, 0, 0, 0.438);
  position: relative;
  text-align: center;
  font-size: 1rem;
  width: 8rem;
  padding: 0.6rem;
  color: #ffc635;
  border: 3px solid;
  cursor: pointer;
  border-radius: 7rem;
  transition: 0.5s;

  display: block;
}

.BtnHome::after, .BtnHome::before {
  content: "";
  position: absolute;
  opacity: 0.3;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  left: 0;
  bottom:0;
  z-index: -1;
  transition: transform 0.3s ease;
}

.BtnHome:hover {
  transform: translate(-12px, -12px);
  background-color: #fffefd;
}

.BtnHome:hover::after {
  transform: translate(-6px, -6px);
}

.BtnHome:hover::before {
  transform: translate(-6px, -6px);
}

.BtnHome {
  margin: 0;
    position: relative;
    top: 1rem;
    left: 40vw;
    height: 2.5rem;
    width: 7rem;
  }