.Aboutdiv {
    position: relative;
    margin: auto;
    top: 15vh;
    width: 75%;
    height: 30%;
    border: 0.1rem solid #ffc635;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.637);
}


.Para {
    color: #ffc635;
    text-align: left;
    right: 5rem;
    padding: 0.5rem;
    font-size: 0.8em;
    
}