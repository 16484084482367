/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 2.5em;
    height: 2em;
    left: 2%;
    top: 3%;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #ffc635;;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #ffc635;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 2em;
    width: 2em;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #000000;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #000000d0;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #ffc635;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;

  color: #ffc635;
  margin-bottom: 1rem;
  text-align: center;
  text-decoration: none;
  transition: color 0.2s;
  }

  .bm-item:hover {
    color: #ffffff;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .menuTitle{
      color: #ffc635;
      font-size: 1.8rem;
      margin-left: -30%;
      margin-top: -20%;
      text-align: center;
  }