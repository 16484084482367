
.card-grid {
    margin-top: 10vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 2%;
    width: 50%;
    gap: 10% 10%;
}

.mainCARD {
    position: absolute;
    left: 65%;
    top: 25vh;
}

@media (max-width: 920px) {
    .mainCARD{
        left: 15%;
        top: 45vh;
    }
}

.mainCARD img {
    display: block;
    border: 2px solid;
    border-radius: 10px;

    width: 80%;
    height: 10%;
}

.mainBack1 {
    transition: all ease-in 0.2s;
    transition-delay: 0.2s;
    background-color: white;
    border-radius: 2rem;
    position: relative;
    bottom: 2rem;
    right: 20%;
    width: 30vw;
    height: 30vh;

    text-align: center;
}

@media (max-width: 920px) {
    .mainBack1 {
        width: 80vw;
        height: 15vh;
        right: 7%;
    }
}


.mainBack2 {
    transition: all ease-in 0.2s;
    transition-delay: 0.2s;
    background-color: white;
    border-radius: 2rem;
    position: relative;
    bottom: 1rem;
    right: 20%;
    width: 30vw;
    height: 30vh;

    text-align: center;
}

@media (max-width: 920px) {
    .mainBack2 {
        width: 80vw;
        height: 15vh;
        right: 7%;
    }
}

.mainCARD .main-Ayah {
    font-size: 4rem;
    position: absolute;
    width: 100%;
    top: 10%;
}

.mainCARD .main-Translit {
    font-size: 1rem;
    position: absolute;
    width: 100%;
    top: 70%;
}

@media (max-width: 920px) {
    .mainCARD .main-Ayah{
        font-size: 1rem;
    }
    .mainCARD .main-Translit{
        font-size: 0.5rem;
    }
}

.score {
    font-size: 4rem;
    color: #ffc635;
    position: relative;
    right: 120%;
    bottom: 2rem;
}

@media (max-width: 920px){
    .score {
        font-size: 1.5rem;
        position: absolute;
        left: 50%;
        bottom: 170%;
        width: 8rem;
        

    }
}

.Alert {
    background-color: #ffc635 !important;
    color: black !important;
    font-weight: bold;
    position: relative;
    width: 30%;
    top: -60vh;
    left: 59%;
}

@media (max-width: 920px){
    .Alert{
        position: relative;
        width: 80%;
        top: 50vh;
        left: 10%;

    }
}
