*{ /*star targets all elements to reset margin and remove whitespace*/
    margin: 0;
    padding: 0;
  }

  body {
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }

  .BtnMenu {
    background-color: rgba(0, 0, 0, 0.438);
    position: relative;
    text-align: center;
    font-size: 1rem;
    width:  85% !important;
    padding: 2rem;
    color: #ffc635;
    border: 0.2rem solid;
    cursor: pointer;
    border-radius: 7rem;
    transition: 1.2s;   
    margin: 2rem;
  

    display: block;
}

.BtnMenu::after, .BtnMenu::before {
    content: "";
    position: absolute;
    opacity: 0.3;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    left: 0;
    bottom:0;
    z-index: -1;
    transition: transform 0.3s ease;
}

.BtnMenu:hover {
    background-color: #fffefd;
}

.BtnMenu:hover::after {
    transform: translate(-6px, -6px);
}

.BtnMenu:hover::before {
    transform: translate(-6px, -6px);
}

.Slide{
    position: relative;
    top: 10%;
    width: 100%;
    left: 5vw;
    
}

@media (max-width: 720px) {
    .Slide{
        position: relative;
        top: 7%;
        left: 0%;

    }
}